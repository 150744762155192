import { Any } from '@bufbuild/protobuf'
import { AssetType } from '@dtx-company/connect-codegen/src/gen/asset/v1/asset_pb'
import { Code } from '@dtx-company/connect-codegen/src/gen/codes/v2/code_pb'
import { FcGeneratorOptions, optionsDefaults } from '@dtx-company/flowcode-generator-browser'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { UnifiedAsset } from '../types'
import { Page as V1Page } from '@dtx-company/connect-codegen/src/gen/pages/v1/page_pb'
import { resolveHref } from 'next/dist/client/resolve-href'
import Router from 'next/router'

//unpacks the Any data value of an asset based on its type, returns this value in accessible format
export const unpackAssetData = (
  data: Any | undefined
): { codeData?: Code; pageV1Data?: V1Page } => {
  if (data?.is(Code)) {
    const codeData = new Code()
    data.unpackTo?.(codeData)
    return { codeData }
  } else if (data?.is(V1Page)) {
    const pageV1Data = new V1Page()
    data.unpackTo?.(pageV1Data)
    return { pageV1Data }
  }
  return {}
}

export const getAssetCardParentHref = (asset: UnifiedAsset | undefined): string | undefined => {
  switch (asset?.assetType) {
    case AssetType.CODE:
      const [, resolvedUrl] = resolveHref(
        Router,
        {
          pathname: Routes.CODE,
          query: {
            batchId: asset?.codeData?.batchId || ''
          }
        },
        true
      )
      return resolvedUrl
    case AssetType.PAGE:
      return `${Routes.PAGES}/${asset?.id}`
    case AssetType.UNSPECIFIED:
      return asset?.isFolder ? `${Routes.FOLDERS}?id=${asset?.id}` : undefined
    default:
      return undefined
  }
}

export const getAssetCardAnalyticsHref = (asset: UnifiedAsset | undefined): string | undefined => {
  switch (asset?.assetType) {
    case AssetType.CODE:
      return `${Routes.ANALYTICS_CODES}?flowcode_id=${asset?.codeData?.batchId ?? ''}`
    case AssetType.PAGE:
      return `${Routes.ANALYTICS_PAGES}?page_id=${asset?.id}`
    case AssetType.UNSPECIFIED:
      return asset?.isFolder ? `${Routes.ANALYTICS_CODES}?folder_id=${asset?.id}` : undefined
    default:
      return undefined
  }
}
export const convertCodeConfigToGeneratorOptions = (
  codeData: Code | undefined,
  redirectData?: string
): FcGeneratorOptions | undefined => {
  const codeDesignFields = codeData?.codeDesign?.config?.fields
  const isStaticCode = codeData?.isStatic ?? false

  const resolvedFields: FcGeneratorOptions = {}
  if (codeDesignFields) {
    Object.keys(codeDesignFields).forEach(function (k: string) {
      resolvedFields[k as keyof FcGeneratorOptions] = codeDesignFields[k].toJson()
    })
    const resolvedRedirectData = isStaticCode ? codeData?.destination[0]?.value : redirectData
    return {
      ...resolvedFields,
      data: resolvedRedirectData || resolvedFields.data
    } as FcGeneratorOptions
  }
  return undefined
}

export const getFlowcodeOptionsFromUnifiedAsset = (asset: UnifiedAsset): FcGeneratorOptions => {
  const defaultCodeDesign = { ...optionsDefaults, data: asset.codeData?.shortUrl ?? '' }

  const convertedAssetOptions = convertCodeConfigToGeneratorOptions(
    asset.codeData,
    asset.codeData?.shortUrl
  )
  return convertedAssetOptions ?? defaultCodeDesign
}

/**
 * Converts encoded folder path to array of correctly formatted strings
 * @param {string} path - The string value of a path from the assets service.
 * @returns {string[]} The full formatted split path.
 *
 * */
export const splitAndDecodeFolderPath = (path?: string): string[] => {
  if (!path) return []
  const splitArray = path?.split('/')
  const decodedPathArray = splitArray.map(path => {
    return path.replaceAll('%2F', '/')
  })
  return decodedPathArray
}

/**
 * Converts a string path array to a single string to send to assets service
 * @param {string[]} splitPath - The string value of a path from the assets service.
 * @param {number} numLevelsBack - Optional number of levels back from end of path to not include. Defaults to 0
 * @returns {string} The encoded path. Returns empty string if numLevelsBack is higher than path
 *
 * */
export const combineAndEncodeFolderPath = (splitPath: string[], numLevelsBack?: number): string => {
  if ((numLevelsBack ?? 0) >= splitPath.length) return ''
  const encodedSplitPath = splitPath.map(folderName => {
    return folderName.replaceAll('/', '%2F')
  })
  const path = encodedSplitPath.slice(0, splitPath.length - (numLevelsBack ?? 0)).join('/')
  return path
}
