import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { useRouter } from 'next/router'

export function usePageTemplateEditorState(): { isTemplateEditor: boolean; templateId?: string } {
  const router = useRouter()
  const isTemplateEditor = router.pathname === Routes.PAGE_TEMPLATE_EDITOR
  const queryId = Array.isArray(router.query.id) ? router.query.id[0] : router.query.id
  return {
    isTemplateEditor,
    templateId: isTemplateEditor ? queryId : undefined
  }
}
