/**
 * Formats a bigint value into a string representation
 * Numbers larger than 10 thousand are collapsed with K,M,B notation
 * @param {bigint} bigIntValue - The bigint value to format.
 * @returns {string} The formatted string representation of the bigint.
 *
 * */
export const formatBigInt = (bigIntValue: bigint): string => {
  const bill = 1000000000n
  const mill = 1000000n
  const thou = 1000n
  const tenThou = 10000n

  if (bigIntValue < tenThou) {
    return bigIntValue.toString()
  } else if (bigIntValue < mill) {
    return (bigIntValue / thou).toString() + 'K'
  } else if (bigIntValue < bill) {
    return (bigIntValue / mill).toString() + 'M'
  } else {
    return (bigIntValue / bill).toString() + 'B'
  }
}

export const formatPercentage = (percentageValue: number): string => {
  return `${Number((percentageValue * 100).toFixed(2))}%`
}
