import { RootPageState } from '../../redux/types'
import { usePageSelector } from '../usePageSelector'

export function useCurrentPageId(): string | null {
  const { pageId } = usePageSelector((state: RootPageState) => {
    return {
      pageId: state.pageEditorReducer?.pageId ?? null
    }
  })
  return pageId
}
