import { FlowpageCount } from '@dtx-company/connect-codegen/src/gen/analytics/v2/analytics_pb'
import { LeanPage, PaginatedLeanPages } from '@dtx-company/flow-codegen/src/page/typeUtils'
import {
  Like_Expression,
  Order_Direction,
  PageSearch,
  Pattern
} from '@dtx-company/flow-codegen/src/page/generated.types'
import { PageManagementState } from '../pageManagementSlice'
import { SortDirections } from '@dtx-company/true-common/src/types/generic'
import { fetchFlowpageAnalytics } from '@app/code/src/components/UnifiedAssetManagement/shared/hooks/fetchAdditionalAssetInfo'
import { formatBigInt, formatPercentage } from '@dtx-company/inter-app/src/utils/assetMgmt'
import { useAuthState } from '../../../hooks/useAuthState'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLazyPageManagementQuery, usePageManagementQuery } from '../flowpageApiSlice/pageQueries'
import uniqBy from 'lodash/uniqBy'

function _sortDirectionToOrderDirection(direction: SortDirections): Order_Direction {
  switch (direction) {
    case SortDirections.ASC:
      return Order_Direction.Asc
    case SortDirections.DSC:
    default:
      return Order_Direction.Desc
  }
}
export function usePaginatedFilteredOrSortedPages(
  pagesLength: number,
  pagesFilter: PageManagementState['pagesFilter'],
  ithacaFilter: PageManagementState['ithacaFilter'],
  enableFetch = true
): {
  pages: PaginatedLeanPages
  getNextPage: () => void
  hasNextPage: boolean
  clearPages: () => void
  loading: boolean
} {
  const { direction, name: filterName, contains } = pagesFilter
  const [pages, setPages] = useState<PaginatedLeanPages>([])
  const [pageAnalytics, setPageAnalyics] = useState<
    | {
        [key: string]: FlowpageCount
      }
    | undefined
  >(undefined)
  const { token } = useAuthState()
  const [trigger, { data, isFetching }] = useLazyPageManagementQuery({})
  const hasNextPage = Boolean(data?.me.pageConnection?.pageInfo?.hasNextPage)

  const getSearchArgs = (contains: string): PageSearch => ({
    OR: [
      {
        slugName: {
          value: contains,
          expression: Like_Expression.Ilike,
          pattern: Pattern.Prefix
        }
      },
      {
        title: {
          value: contains,
          expression: Like_Expression.Ilike,
          pattern: Pattern.Prefix
        }
      },
      {
        assetTags: {
          tagName: {
            value: contains,
            expression: Like_Expression.Ilike,
            pattern: Pattern.Prefix
          }
        }
      }
    ]
  })

  const getNextPage = (): void => {
    const after = data?.me.pageConnection?.pageInfo?.endCursor
    trigger({
      first: pagesLength,
      after,
      ithacaFilter,
      orderBy: filterName,
      orderDirection: _sortDirectionToOrderDirection(direction),
      ...(contains && { where: getSearchArgs(contains) })
    })
  }

  const clearPages = (): void => {
    setPages([])
    setPageAnalyics({})
  }

  useEffect(() => {
    if (!enableFetch) return
    const abort = new AbortController()
    clearPages()
    trigger({
      first: pagesLength,
      ithacaFilter,
      orderBy: filterName,
      orderDirection: _sortDirectionToOrderDirection(direction),
      ...(contains && { where: getSearchArgs(contains) })
    })
    return () => abort.abort()
  }, [enableFetch, contains, direction, filterName, pagesLength, trigger, ithacaFilter])

  const fetchNextPagesAnalytics = useCallback(
    async (newPages: PaginatedLeanPages): Promise<void> => {
      if (newPages.length === 0) return
      const newPageIds = newPages.map(page => page.id)
      const newAnalytics = await fetchFlowpageAnalytics({ pageIds: newPageIds, token })
      setPageAnalyics(prevState => {
        return { ...prevState, ...newAnalytics }
      })
    },
    [token]
  )

  useEffect(() => {
    if (data?.me.pageConnection?.edges) {
      setPages(prevState => {
        const updatedPages = uniqBy([...prevState, ...(data?.me.pageConnection?.edges ?? {})], 'id')
        if (updatedPages.length !== prevState.length) {
          fetchNextPagesAnalytics(data?.me.pageConnection?.edges ?? [])
        }
        return updatedPages
      })
    }
  }, [data?.me.pageConnection?.edges, fetchNextPagesAnalytics])

  const pagesWithAnalytics = useMemo(() => {
    return pages.map(page => {
      return {
        ...page,
        analytics: {
          pageViews: formatBigInt(pageAnalytics?.[page.id]?.count ?? 0n),
          clickThroughRate: formatPercentage(pageAnalytics?.[page.id]?.clickthroughRate ?? 0)
        }
      }
    })
  }, [pages, pageAnalytics])

  return {
    pages: pagesWithAnalytics,
    clearPages,
    getNextPage,
    hasNextPage,
    loading: isFetching
  }
}

export function useGetFirstPage(): LeanPage | null {
  const { isAuthenticated } = useAuthState()
  const { data } = usePageManagementQuery(
    { first: 1 },
    {
      skip: !isAuthenticated
    }
  )
  return data?.me?.pageConnection?.edges?.[0] || null
}
